<template>
  <div class="cart-icon">
    <div class="relative z-[31]" @mouseenter="!props.hoverDisabled && view.greaterOrEqual('desktop').value && props.cartItems?.length && openCartMenu()" @mouseleave="!props.hoverDisabled && view.greaterOrEqual('desktop').value && props.cartItems?.length && closeCartMenu()">

      <AtomsTextLink :href="props.cartItems.length ? cache.getModuleUrl('modules/order/cart') : cache.getModuleUrl('modules/order/emptyCart')" class="relative flex items-center whitespace-nowrap hover:no-underline z-[31]" rel="nofollow">
        <span class="relative tablet:mr-4">
          <AtomsImagesIcon icon="shopping-cart" size="lg tablet:text-2xl" class="tablet:text-neutral-500 tablet:p-[0.5em] tablet:bg-neutral-50 rounded-full" />
          <div class="absolute -right-5 -top-3 scale-75 flex items-center justify-center w-6 aspect-square bg-secondary-500 rounded-full text-xs font-bold tablet:scale-100 tablet:-right-3 tablet:-top-3">{{ props.totalQuantity }}</div>
        </span>
        <span class="hidden mx-2 tablet:inline">{{ $t('Košík') }}</span>
        <span class="hidden tablet:inline-block min-w-[75px] font-bold text-right">
          <MoleculesGlobalAutocounter :value="props.totalPrice" :max-one-decimal="true" />
        </span>
      </AtomsTextLink>

      <div v-if="isCartMenuOpen" class="cart-icon__menu" :class="{ 'cart-icon__menu--is-open': isCartMenuOpen }" data-pageelementname="cart_dropdown" data-viewelementsingle="true">

        <template v-for="item in props.cartItems">

          <!-- cartItem -->

          <AtomsTextLink :href="item.url" class="cart-icon__menu__item" data-pageelementname="cart_dropdown" data-viewelementsingle="true">
            <MoleculesGlobalWareImage :ware-name="item.name" :image-id="item.imageId" suffix="pl" />
            <div class="space-y-2">
              <strong class="font-bold">{{ item.name }}</strong>
              <div v-html="item.variantName" class="text-sm"></div>
              <div class="cart-icon__menu__item__row">
                <span class="cart-icon__menu__item__price">{{ locale.formatPrice(item.piecePrice, false, undefined, undefined, 2) }}</span>
                <span class="cart-icon__menu__item__quantity">{{ $t('ks', item.quantity) }}</span>
              </div>
            </div>
          </AtomsTextLink>

          <!-- cartItem´s services -->

          <AtomsTextLink v-for="serviceItem in item.orderedServices" :href="item.url" class="cart-icon__menu__item" data-pageelementname="cart_dropdown" data-viewelementsingle="true">
            <div class="flex items-center justify-center w-[120px] h-[120px]">
              <AtomsImagesIcon icon="laser" class="text-[5rem]" />
            </div>
            <div class="space-y-2">
              <strong class="font-bold">{{ serviceItem.name }}</strong>
              <div v-html="serviceItem.inputText" class="text-sm"></div>
              <div class="cart-icon__menu__item__row">
                <span class="cart-icon__menu__item__price">{{ locale.formatPrice(serviceItem.serviceOption.price, false, undefined, undefined, 2) }}</span>
              </div>
            </div>
          </AtomsTextLink>

        </template>

        <div v-if="cart.prices.value.itemsCouponSale.value" class="flex mt-3 -mb-6 p-3 justify-between items-center">
          <span class="uppercase">{{ $t('Slevový kupón') }}</span>
          <strong class="text-xl">
            <MoleculesGlobalAutocounter :value="-cart.prices.value.itemsCouponSale.value" :max-one-decimal="true" />
          </strong>
        </div>

        <div class="flex my-3 p-3 justify-between items-center">
          <span class="uppercase">{{ $t('Celkem') }} ({{ $t('položka', props.totalQuantity) }})</span>
          <strong class="text-2xl">
            <MoleculesGlobalAutocounter :value="props.totalPrice" :max-one-decimal="true" />
          </strong>
        </div>
        <MoleculesButtonsButton :href="cache.getModuleUrl('modules/order/cart')" class="w-full" data-pageelementname="cart_dropdown" data-viewelementsingle="true">{{ $t('Přejít do košíku') }}</MoleculesButtonsButton>
      </div>
    </div>

    <AtomsGlobalOverlay :isVisible="isCartMenuOpen" @click="closeCartMenu" />
  </div>
</template>
<script setup>

import { useBreakpoints } from '@vueuse/core';

const props = defineProps({
  cartItems: Array,
  totalQuantity: Number,
  totalPrice: Number,
  hoverDisabled: Boolean
});

const appConfig = useAppConfig();
const cart = useCart();
const cache = useCache();
const i18n = useI18n();
const view = useBreakpoints(appConfig.breakpoints);
const locale = useLocale();

const isCartMenuOpen = ref(false);

const openCartMenu = () => isCartMenuOpen.value = true;
const closeCartMenu = () => isCartMenuOpen.value = false;

</script>
<style lang="postcss">
.cart-icon {

  &__menu {
    @apply absolute right-0 p-6 pt-0 top-[100%] -z-[1] w-[380px] max-h-[0] opacity-0 overflow-hidden border-t-[15px] border-neutral-500 bg-neutral-50 text-neutral-400 whitespace-normal transition-all;

    &__item {
      @apply flex gap-6 py-6 border-b border-neutral-300 tracking-normal hover:no-underline;

      img {
        @apply max-w-[120px] max-h-[120px];
      }

      &__row {
        @apply flex gap-2 justify-between items-center;
      }

      &__price {
        @apply text-xl font-bold text-primary-500;
      }

      &__quantity {
        @apply min-w-[5rem] text-center;
      }
    }

    &--is-open {
      animation: slide-toggle 0.7s;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;
    }
  }
}
</style>